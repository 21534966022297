import {
  Box,
  Button,
  Container,
  Link,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';

const CardStyle = styled(Box)({
  position: 'fixed',
  bottom: '0',
  left: '0',
  zIndex: '999',
  width: '100%',
  padding: '20px',
  background: 'rgba(0, 0, 0, 0.8)',
});

const ButtonStyle = styled(Button)({
  marginLeft: '0.5rem',
  borderRadius: '8px',
  height: '36px',
  fontSize: '0.875rem',
});

const CustomLink = styled(Link)({
  display: 'inline-block',
  color: 'inherit',
  opacity: 0.7,
  textDecoration: 'underline',
});

const CookiesConsent = ({ onAccept = () => {}, setIsShowDialogConsent }) => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <CardStyle>
      <Container
        className={`d-flex ${
          smUp
            ? 'justify-space-between align-center'
            : 'flex-column justify-center align-center'
        }`}
      >
        <Box>
          <Typography className="mb-1" style={{ color: 'white' }}>
            เว็บไซต์นี้มีการใช้งานคุกกี้
            เพื่อเพิ่มประสิทธิภาพและประสบการณ์ที่ดีในการใช้งานเว็บไซต์ของท่าน
            ท่านสามารถอ่านรายละเอียดเพิ่มเติมได้ที่
            <br />
            <CustomLink href="https://www.muangthai.co.th/th/privacy-policy" className="mr-1">
              นโยบายความเป็นส่วนตัว
            </CustomLink>
            และ
            <CustomLink href="https://www.muangthai.co.th/th/Cookie-Policy-And-Setting" className="mx-1">
              นโยบายคุกกี้
            </CustomLink>
          </Typography>
        </Box>
        <Box
          className={`d-flex flex-shrink ${smUp ? 'justify-end pl-4' : 'pt-4'}`}
        >
          <ButtonStyle
            variant="outlined"
            className="flex-shrink"
            onClick={() => {
              setIsShowDialogConsent(true);
            }}
            style={{ color: 'white', borderColor: 'white' }}
          >
            ตั้งค่าคุกกี้
          </ButtonStyle>
          <ButtonStyle
            onClick={onAccept}
            variant="text"
            className="flex-shrink"
            style={{ background: 'white', color: 'initial' }}
          >
            ยอมรับทั้งหมด
          </ButtonStyle>
        </Box>
      </Container>
    </CardStyle>
  );
};

export default CookiesConsent;
