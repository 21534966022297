/**
 *
 * Header
 *
 */

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import { useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Zoom from '@material-ui/core/Zoom';
import EmailRounded from '@material-ui/icons/EmailRounded';
import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import logo from '../images/master-brand-white.png';
import Breadcrumb from './Breadcrumb';
import DialogShare from './DialogShare';
// import { createFileName } from 'use-react-screenshot';
// import IconButton from '@material-ui/core/IconButton';
// import Slide from '@material-ui/core/Slide';

const ElevationScroll = (props) => {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 1 : 0,
    color: trigger ? 'inherit' : 'transparent',
  });
};

function Header({ breadcrumb, isShare, capture, shareDetail }) {
  const { pathname } = useLocation();
  const [isShowDialogShare, setIsShowDialogShare] = useState(false);

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      <ElevationScroll>
        <AppBar
          elevation={0}
          position={pathname === '/' && smUp ? 'fixed' : 'sticky'}
        >
          <Container maxWidth="xl" className="pa-0">
            <Toolbar
              className={`flex justify-space-between ${
                smUp ? 'px-6' : 'pl-0 pr-2'
              }`}
            >
              <div className="flex align-center">
                <NavLink to="/" className="logo flex-shrink">
                  <img src={logo} alt="" height={smUp ? 48 : 24} />
                </NavLink>
                <Breadcrumb
                  links={breadcrumb.links}
                  current={breadcrumb.current}
                ></Breadcrumb>
              </div>

              <div className="flex align-center">
                <Button
                  href={process.env.REACT_APP_MY_TOOL_URL}
                  variant="outlined"
                  size={smUp ? 'medium' : 'small'}
                  className="my-tool flex-shrink"
                >
                  My tool
                </Button>
              </div>
            </Toolbar>
          </Container>
        </AppBar>
      </ElevationScroll>

      <Zoom in={isShare} unmountOnExit>
        <Tooltip title="ส่งอีเมล" arrow>
          <Fab
            color="secondary"
            className="share"
            onClick={() => {
              setIsShowDialogShare(true);
            }}
          >
            <EmailRounded />
          </Fab>
        </Tooltip>
      </Zoom>

      <DialogShare
        isShow={isShowDialogShare}
        close={() => {
          setIsShowDialogShare(false);
        }}
        capture={capture}
        shareDetail={shareDetail}
      ></DialogShare>
    </>
  );
}

Header.propTypes = {
  breadcrumb: PropTypes.object,
  isShare: PropTypes.bool,
  capture: PropTypes.func,
};

export default memo(Header);
