/**
 *
 * Products
 *
 */

import Grid from '@material-ui/core/Grid';
import React, { createRef, memo, useEffect, useState } from 'react';
import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { get } from 'lodash';
import axios from 'axios';
import { useScreenshot } from 'use-react-screenshot';
import Header from '../components/Header';
import LoadingContent from '../components/LoadingContent';
import ProductCard from '../components/ProductCard';
import plans from '../mock-data/plans';
import products from '../mock-data/products';
import ProductDetail from '../pages/ProductDetail';

// import PropTypes from 'prop-types';

const isEncoded = str => {
  try {
    return decodeURIComponent(str) !== str
  } catch {
    return false
  }
}

const permalinkCompare = (first, second) => {
  if (
    first === second ||
    (isEncoded(first) ? first : encodeURIComponent(first)) === second ||
    first === (isEncoded(second) ? second : encodeURIComponent(second)) ||
    (isEncoded(first) ? first : encodeURIComponent(first)) === (isEncoded(second) ? second : encodeURIComponent(second))
  ) return true
  return false
}

function Products() {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { planId } = useParams();
  const { choiceId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [plan, setPlan] = useState({});
  const [choice, setChoice] = useState({});

  const screenshotRef = createRef(null);
  const [, takeScreenShot] = useScreenshot({
    type: 'image/jpeg',
    quality: 1.0,
  });

  const capture = () => takeScreenShot(screenshotRef.current);

  useEffect(async () => {
    const planResponse = await axios.post(process.env.REACT_APP_BACKEND_API_URL + '/data/queryplan/'+planId)
    const planData = get(planResponse, 'data.response', {})
    const choices = get(planData, 'choices', []) || [];
    const choiceData = choices.find(c => c.id === choiceId || permalinkCompare(c.permalink, choiceId))
    if (!choiceData) {
      history.push(`/plan/${planId}`)
    } else {
      setPlan(planData);
      setChoice(choiceData);
      setIsLoading(false);
    }
  }, [choiceId, planId]);

  return (
    <Switch>
      <Route path={`${path}/:productId`}>
        <ProductDetail
          breadcrumb={{
            links: [
              { path: '/', name: 'หน้าแรก' },
              { path: `/plan/${planId}`, name: plan.title },
              { path: `/plan/${planId}/${choiceId}`, name: choice.title },
            ],
          }}
        />
      </Route>
      <Route path={path}>
        <Header
          breadcrumb={{
            links: [
              { path: '/', name: 'หน้าแรก' },
              { path: `/plan/${planId}`, name: plan.title },
            ],
            current: choice.title,
          }}
          isShare
          capture={capture}
          shareDetail={{ id: choice.id, type: 'products' }}
        />
        <div ref={screenshotRef} className="products-page pt-6">
          {isLoading ? (
            <LoadingContent />
          ) : (
            <>
              <h1 className="mb-8 text-center px-6">{`${plan.title} - ${plan.caption}`}</h1>

              <div className="top-three">
                <Grid container spacing={3}>
                  {(get(choice, 'top_products', []) || []).length > 0 &&
                    (get(choice, 'top_products', []) || []).map((r, idx) => {
                      const product = (get(choice, 'top_products_data', []) || []).find((p) => p.id === r);
                      if (!product) return null;
                      return (
                        <Grid item xs={12} sm={4} key={r}>
                          <ProductCard
                            rank={idx + 1}
                            product={product}
                            className="flex-grow mx-2"
                            url={`/plan/${planId}/${choiceId}/${product.permalink || product.id}`}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </div>

              <div className="products px-6 py-8">
                <Grid container spacing={3}>
                  {(get(choice, 'products', []) || []).length > 0 &&
                    (get(choice, 'products', []) || []).map((r) => {
                      const product = (get(choice, 'products_data', []) || []).find((p) => p.id === r);
                      if (!product) return null;
                      return (
                        <Grid item xs={12} sm={4} key={r}>
                          <ProductCard
                            product={product}
                            url={`/plan/${planId}/${choiceId}/${product.permalink || product.id}`}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </div>
            </>
          )}
        </div>
      </Route>
    </Switch>
  );
}

Products.propTypes = {};

export default memo(Products);
