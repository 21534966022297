/**
 *
 * ProductDetail
 *
 */

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React, { createRef, memo, useEffect, useState } from "react";
import axios from "axios";
import { get } from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { useScreenshot } from "use-react-screenshot";
import Header from "../components/Header";
import LoadingContent from "../components/LoadingContent";
// import HtmlContent from '../components/HtmlContent';
import HtmlContent from "../components/HtmlContent";

function ProductDetail({ breadcrumb }) {
  const history = useHistory();
  const { productId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [product, setProduct] = useState({});

  const screenshotRef = createRef(null);
  const [, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0
  });

  const capture = () => takeScreenShot(screenshotRef.current);

  useEffect(async () => {
    const productResponse = await axios.post(
      process.env.REACT_APP_BACKEND_API_URL + "/data/queryproduct/" +
        productId
    );
    const productData = get(productResponse, "data.response", {});
    if (!productData.id) {
      history.push('/')
    } else {
      setProduct(productData);
      setIsLoading(false);
    }
  }, [productId]);

  const getHtmlContent = index => {
    let content = "";
    if (get(product, "content_image_urls." + index)) {
      if (index === 0) {
        content = `<img src='${product.content_image_urls[index]}' /><br/>`;
      } else if (index === 1) {
        content = `<img src='${product.content_image_urls[index]}' /><br/>`;
      }
    }

    return content;
  };

  return (
    <>
      <Header
        breadcrumb={{ ...breadcrumb, current: product.title }}
        isShare
        capture={capture}
        shareDetail={{ id: product.id, type: "product-detail" }}
      />
      <div ref={screenshotRef} className="product-detail-page pa-6">
        {isLoading ? (
          <LoadingContent />
        ) : (
          <>
            <h1 className="mb-6">{product.title}</h1>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <div className="box-1-1 fit-height radii">
                  <HtmlContent content={getHtmlContent(0)} />
                </div>
              </Grid>

              <Grid item xs={12} sm={6}>
                <div className="box-4-3 fit-height radii">
                  <HtmlContent content={getHtmlContent(1)} />
                </div>

                <div className="flex align-baseline">
                  <p className="mr-2" style={{ marginLeft: '70px' }}>
                    สนใจซื้อแบบประกัน
                  </p>
                  <Button
                    variant="text"
                    href={process.env.REACT_APP_MY_TOOL_URL}
                  >
                    คลิก
                  </Button>
                </div>
              </Grid>

              {/* <HtmlContent content={} /> */}
            </Grid>
          </>
        )}
      </div>
    </>
  );
}

ProductDetail.propTypes = {
  breadcrumb: PropTypes.object
};

export default memo(ProductDetail);
