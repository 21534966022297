/**
 *
 * PlanCard
 *
 */

import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function PlanCard({ plan, timeout, className }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Grow in={show} timeout={timeout}>
      <Paper className={`hover-card plan-card ${className}`}>
        <Link to={`/plan/${plan.permalink}`}>
          <div className="box-1-1 fit-height radii">
            <img src={plan.image_url} alt="" />
          </div>
          <div className="overlay px-8 py-6 radii flex flex-column justify-center align-center">
            <p className="no-ellipsis">{plan.title}</p>
          </div>
        </Link>
      </Paper>
    </Grow>
  );
}

PlanCard.propTypes = {
  plan: PropTypes.object,
  timeout: PropTypes.number,
  className: PropTypes.string,
};

export default memo(PlanCard);
