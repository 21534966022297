/**
 *
 * DialogShare
 *
 */

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import CloseRounded from '@material-ui/icons/CloseRounded';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';

function DialogShare({ isShow, close, shareDetail }) {
  const [email, setEmail] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const submitSendEmail = () => {
    executeRecaptcha('login_page').then((recaptchaValue) => {
      setIsLoading(true);

      const value = {
        to_addresses: [email],
        recaptcha_value: recaptchaValue,
        email_type: shareDetail.type,
        email_id: shareDetail.id,
      };

      axios
        .post(
          process.env.REACT_APP_EMAIL_API_URL,
          value,
        )
        .then((res) => {
          setSuccessMsg('Email has been sent successfull');
          setTimeout(() => {
            handleClose();
          }, 2000);
        })
        .catch((err) => {
          setErrMsg(err && err.message ? err.message : 'Error');
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  const validateEmail = () => {
    var format = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (format.test(email.toLowerCase())) {
      submitSendEmail();
    } else {
      setErrMsg('Invalid emial address');
    }
  };

  const handleClose = () => {
    close();
    setIsLoading(false);
    setEmail('');
    setErrMsg('');
    setTimeout(() => {
      setSuccessMsg('');
    }, 500);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={isShow}
      maxWidth="xs"
      fullWidth
    >
      <div className="dialog-share">
        <div className="flex align-center justify-space-between px-6 py-4">
          <h3>โปรดระบุอีเมลที่ต้องการส่ง</h3>
          <IconButton onClick={handleClose}>
            <CloseRounded />
          </IconButton>
        </div>

        <div className="pa-6 pt-0">
          {errMsg !== '' && (
            <Alert
              severity="error"
              className="mb-4"
              onClose={() => {
                setErrMsg('');
              }}
            >
              {errMsg}
            </Alert>
          )}

          {successMsg !== '' && (
            <Alert severity="success" className="mb-4">
              {successMsg}
            </Alert>
          )}
          {successMsg === '' && (<>
            <div className="flex">
              <TextField
                variant="outlined"
                fullWidth
                type="email"
                placeholder="อีเมล"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    validateEmail();
                  }
                }}
              />
              <Button
                type="submit"
                onClick={validateEmail}
                variant="contained"
                disableElevation
                startIcon={
                  isLoading ? (
                    <CircularProgress size="20px" color="#fff" />
                  ) : (
                    <></>
                  )
                }
                className="submit flex-shrink"
              >
                {isLoading ? 'กำลังส่ง' : 'ส่ง'}
              </Button>
            </div>
            <p className="text-captcha">This site is protected by reCAPTCHA and the Google{' '}
              <a href="https://policies.google.com/privacy">Privacy Policy</a>{' '}and{' '}
              <a href="https://policies.google.com/terms">Terms of Service</a>{' '}apply.
            </p>
          </>
          )}
        </div>
      </div>
    </Dialog>
  );
}

DialogShare.propTypes = {
  isShow: PropTypes.bool,
};

export default memo(DialogShare);
