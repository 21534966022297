/**
 *
 * LoadingContent
 *
 */

import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

function LoadingContent({ className }) {
  return (
    <div className={`loading-content ${className}`}>
      <CircularProgress />
    </div>
  );
}

LoadingContent.propTypes = {
  className: PropTypes.string,
};

export default memo(LoadingContent);
