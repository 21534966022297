/**
 *
 * Home
 *
 */

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ChevronRight from "@material-ui/icons/ChevronRight";
import React, { useEffect, useState, memo } from "react";
import axios from "axios";
import { get, sortBy } from "lodash";
import { Link } from "react-router-dom";
import BlogCard from "../components/BlogCard";
import Header from "../components/Header";
import PlanCard from "../components/PlanCard";
import LoadingContent from "../components/LoadingContent";
// import PropTypes from 'prop-types';

function Home() {
  const [isBlogLoading, setIsBlogLoading] = useState(true);
  const [isPlanLoading, setIsPlanLoading] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const [plans, setPlans] = useState([]);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(async () => {
    const blogResponse = await axios.get(
      process.env.REACT_APP_BACKEND_API_URL + "/data/blogs/highlight"
    );
    const getBlogs = get(blogResponse, "data.response", []);
    setBlogs(sortBy(getBlogs, blog => { return parseInt(blog.index) }));
    setIsBlogLoading(false)
  }, []);

  useEffect(async () => {
    const planResponse = await axios.get(
      process.env.REACT_APP_BACKEND_API_URL + "/data/plans"
    );
    const getPlans = get(planResponse, "data.response", []);
    const sortedPlans = sortBy(getPlans, plan => { return parseInt(plan.created_at) } )
    setPlans(sortedPlans);
    setIsPlanLoading(false)

  }, []);

  return (
    <>
      <Header breadcrumb={{ links: [], current: "หน้าแรก" }} />
      <div className="home-page">
        {/* <div className={`${smUp ? 'box-3-1' : 'box-4-3'} fit-height`}> */}
        <img
          src={process.env.REACT_APP_S3_URL + "/uploaded_assets/banner"}
          alt=""
        />
        {/* </div> */}

        <section className="pa-6 bg-gray-white">
          <h2 className="mb-4">เกาะกระแส</h2>
          <Grid container spacing={3} className="mb-2">
            {isBlogLoading ? (
              <div className="col-12">
              <LoadingContent />
              </div>
            ) : (
              blogs
                .map((blog, idx) => (
                  <Grid item xs={12} sm={3} key={blog.id}>
                    <BlogCard blog={blog} timeout={(idx + 1) * 200} animate />
                  </Grid>
                ))
            )}
          </Grid>

          <div className="flex justify-end">
            {!isBlogLoading && (
              <Button
                component={Link}
                variant="text"
                to="/blogs"
                endIcon={<ChevronRight />}
              >
                อ่านเพิ่มเติม
              </Button>
            )}
          </div>
        </section>

        <section className="pa-6 bg-abstract">
          <h2 className="mb-6">การวางแผนต้องตอบโจทย์ชีวิตที่แตกต่าง</h2>

          <Grid container spacing={3} className="mb-2">
            {isPlanLoading ? (
              <div className="col-12">
              <LoadingContent />
              </div>
            ) : (
              plans.map((plan, idx) => (
                <Grid item xs={12} sm={3} key={plan.id}>
                  <PlanCard plan={plan} timeout={(idx + 1) * 200} animate />
                </Grid>
              ))
            )}
          </Grid>
        </section>
      </div>
    </>
  );
}

Home.propTypes = {};

export default memo(Home);
