import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { AddRounded, CloseRounded, RemoveRounded } from '@material-ui/icons';
import React, { useState } from 'react';

const ButtonStyle = styled(Button)({
  marginLeft: '0.5rem',
  borderRadius: '8px',
  height: '36px',
  fontSize: '0.875rem',
});

const AccordionStyle = styled(MuiAccordion)({
  background: '#f5f5f5',
  borderRadius: '8px',
  marginBottom: '8px',
  boxShadow: 'none',

  '&:before': { display: 'none' },
});

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#5f9ea0',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const AccordionSummary = styled(({ expanded, ...props }) => (
  <MuiAccordionSummary
    expandIcon={expanded ? <RemoveRounded /> : <AddRounded />}
    {...props}
  />
))(() => ({
  flexDirection: 'row-reverse',
  padding: '0 16px 0 0',

  '& .MuiAccordionSummary-content': { margin: '0 !important' },
  '& .MuiAccordionSummary-expandIcon': { marginRight: '0', transform: 'none' },
  '& + .MuiCollapse-container p': { lineHeight: '1.5' },
}));

const Accordion = ({
  title,
  subTitle,
  children,
  checked,
  alwayChecked,
  onChange,
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <AccordionStyle
      expanded={expanded}
      onChange={() => {
        setExpanded(!expanded);
      }}
    >
      <AccordionSummary expanded={expanded}>
        <Box className="d-flex align-center justify-space-between flex-grow">
          <Typography>
            {title}{' '}
            <Typography style={{ opacity: '0.7' }} component="span">
              {subTitle}
            </Typography>
          </Typography>

          {alwayChecked ? (
            <Typography style={{ color: '#5f9ea0' }}>
              เปิดใช้งานตลอดเวลา
            </Typography>
          ) : (
            <AntSwitch
              checked={checked}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                onChange(e);
              }}
            />
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails className="pt-0 d-block">{children}</AccordionDetails>
    </AccordionStyle>
  );
};

const CookiesTable = () => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>ชื่อ</TableCell>
          <TableCell>โดเมน</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>aa</TableCell>
          <TableCell>www.google.com</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const CookiesConsentDialog = ({
  isShowDialogConsent,
  setIsShowDialogConsent,
  onAccept = () => {},
  onAcceptAll = () => {},
}) => {
  const onClose = () => {
    setIsShowDialogConsent(false);
  };
  const [enablePerformance, setEnablePerformance] = useState(false)
  const [enableFunction, setEnableFunction] = useState(false)
  const [enableTarget, setEnableTarget] = useState(false)

  return (
    <Dialog
      open={isShowDialogConsent}
      fullWidth
      maxWidth="sm"
      scroll="body"
      onClose={onClose}
    >
      <Box className="pa-6 d-flex justify-space-between align-start">
        <Box>
          <Typography
            style={{ fontSize: '1rem', fontWeight: 'bold', mb: '2px' }}
          >
            ตั้งค่าความเป็นส่วนตัว
          </Typography>
          <Typography>
            คุณสามารถเลือกการตั้งค่าคุกกี้โดยเปิด/ปิด
            คุกกี้ในแต่ละประเภทได้ตามความ ต้องการยกเว้น คุกกี้ที่มีความจำเป็น
          </Typography>
        </Box>
        <IconButton onClick={onClose}>
          <CloseRounded />
        </IconButton>
      </Box>

      <DialogContent className="pa-6 pt-0">
        <Box>
          <Accordion
            title="คุกกี้ที่มีความจำเป็น"
            subTitle="(Strictly Necessary Cookies)"
            alwayChecked
          >
            <Typography variant="body2">
              คุกกี้ประเภทนี้ช่วยให้ท่านสามารถเข้าถึงข้อมูลและใช้งานเว็บไซต์ได้
              เช่น การลงชื่อเข้าใช้เว็บไซต์ หรือการสั่งซื้อสินค้า
              เพื่อให้เว็บไซต์สามารถทำงานได้เป็นปกติ มีความปลอดภัย
              ซึ่งท่านจะไม่สามารถปิดการใช้งานของคุกกี้ประเภทนี้ได้
            </Typography>
          </Accordion>
          <Accordion
            checked={enablePerformance}
            title="คุกกี้เพื่อการวิเคราะห์และประเมินผลการใช้งาน"
            subTitle="(Performance Cookies)"
            onChange={(e) => { setEnablePerformance(e.target.checked) }}
          >
            <Typography variant="body2">
              คุกกี้ประเภทนี้ช่วยเสริมประสิทธิภาพในการใช้บริการ
              เพื่อรวบรวมข้อมูลทางสถิติเกี่ยวกับการสนใจและพฤติกรรมการเยี่ยมชมเว็บไซต์
              อีกทั้งยังใช้ข้อมูลนี้เพื่อการปรับปรุงและพัฒนาการทำงานของเว็บไซต์เพื่อให้มีคุณภาพดีขึ้นและมีความเหมาะสมมากขึ้น
              ในส่วนของข้อมูลที่คุกกี้ที่เก็บรวบรวมนี้จะเป็นข้อมูลที่ไม่สามารถระบุตัวตนได้
            </Typography>
          </Accordion>
          <Accordion
            checked={enableFunction}
            title="คุกกี้เพื่อการใช้งานเว็บไซต์"
            subTitle="(Function Cookies)"
            onChange={(e) => { setEnableFunction(e.target.checked) }}
          >
            <Typography variant="body2">
              คุกกี้ประเภทนี้จะช่วยให้เว็บไซต์ จดจำตัวเลือกต่าง ๆ
              ที่ท่านได้ตั้งค่าไว้และปรับเปลี่ยนไปตามพฤติกรรมและความพึงพอใจของผู้ใช้เว็บไซต์
              เช่น จดจำการล็อกอินของท่าน ,จดจำการตั้งค่าภาษา,
              จดจำสินค้าล่าสุดที่ท่านเคยดู
              เพื่ออำนวยความสะดวกเมื่อท่านกลับเข้ามาใช้งานเว็บไซต์
            </Typography>
          </Accordion>
          <Accordion
            checked={enableTarget}
            title="คุกกี้เพื่อการโฆษณาไปยังกลุ่มเป้าหมาย"
            subTitle="(Targeting Cookies)"
            onChange={(e) => { setEnableTarget(e.target.checked) }}
          >
            <Typography variant="body2">
              คุกกี้ประเภทนี้เป็นคุกกี้ที่เกิดจากการเชื่อมโยงเว็บไซต์ของบุคคลที่สาม
              เช่น Google Analytics, Facebook Pixels ,LINE Tags, Tiktok Pixels
              เพื่อเก็บข้อมูลการเข้าใช้งานและลิงก์ที่ท่านติดตามหรือเยี่ยมชม
              เพื่อให้เข้าใจความต้องการของท่านและใช้ในการปรับปรุงและพัฒนาเว็บไซต์
              รวมถึงการโฆษณาให้เหมาะสมกับความสนใจของท่าน
            </Typography>
          </Accordion>
        </Box>
      </DialogContent>
      <DialogActions className="pa-6 pt-0 d-flex flex-wrap">
        <ButtonStyle
          variant="outlined"
          className="flex-shrink ma-1"
          style={{ color: '#334455', borderColor: '#334455' }}
          onClick={onAcceptAll}
        >
          ยอมรับทั้งหมด
        </ButtonStyle>
        <ButtonStyle
          onClick={() => { onAccept(enablePerformance, enableFunction, enableTarget) }}
          variant="text"
          className="flex-shrink ma-1"
          style={{ background: '#334455', color: 'white' }}
        >
          ยืนยันการตั้งค่า
        </ButtonStyle>
      </DialogActions>
    </Dialog>
  );
};

export default CookiesConsentDialog;
