import DateFnsUtils from '@date-io/date-fns';
import { useCookies } from "react-cookie";
import TagManager from 'react-gtm-module';
import Container from '@material-ui/core/Container';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useState, useCallback, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import CookiesConsent from "./components/CookiesConsent";
import CookiesConsentDialog from "./components/CookiesConsentDialog";
import Blogs from './pages/Blogs';
import Home from './pages/Home';
import Plan from './pages/Plan';
import './stylesheets/global.scss';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM,
};

export const colors = {
  primary: '#5f9ea0',
  secondary: '#6ac6b8',
  info: '#428bca',
  warning: '#ffc107',
  white: '#ffffff',
  bg: '#e8e8e8',
  border: 'rgba(169, 178, 187, 0.3)',
  textPrimary: '#334455',
  textSecondary: '#a9b2bb',
  textDisabled: 'rgba(0, 0, 0, 0.38)',
  textHint: 'rgba(0, 0, 0, 0.38)',
};

const theme = createMuiTheme({
  overrides: {
    MuiAppBar: {
      root: {
        transition: '0.2s',
      },
    },
    MuiButton: {
      root: {
        fontSize: 'initial',
        borderRadius: '40px',
        color: colors.info,
      },
      text: {
        padding: '6px 16px',
      },
      outlined: {
        borderColor: colors.info,
        borderWidth: '2px',
      },
    },
    MuiChip: {
      root: {
        fontSize: '0.875rem',
      },
      colorSecondary: {
        color: colors.white,
      },
    },
  },
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    info: {
      main: colors.info,
    },
    background: {
      default: colors.bg,
      paper: colors.white,
    },
    text: {
      primary: colors.textPrimary,
      secondary: colors.textSecondary,
      disabled: colors.textDisabled,
      hint: colors.textHint,
    },
  },
  typography: {
    htmlFontSize: 18,
    fontFamily: "'Roboto','Kanit', sans-serif",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  shape: {
    borderRadius: 8,
  },
  shadows: [
    'none',
    '0 6px 8px rgba(102,119,136,.03), 0 1px 2px rgba(102,119,136,.3)',
    '0px 3px 1px -2px rgba(102,119,136,.03),0px 2px 2px 0px rgba(102,119,136,.3)',
    '0px 3px 3px -2px rgba(102,119,136,.03),0px 3px 4px 0px rgba(102,119,136,.3)',
    '0px 2px 4px -1px rgba(102,119,136,.03),0px 4px 5px 0px rgba(102,119,136,.3)',
    '0px 3px 5px -1px rgba(102,119,136,.03),0px 5px 8px 0px rgba(102,119,136,.3)',
    '0px 3px 5px -1px rgba(102,119,136,.03),0px 6px 10px 0px rgba(102,119,136,.3)',
    '0px 4px 5px -2px rgba(102,119,136,.03),0px 7px 10px 1px rgba(102,119,136,.3)',
    '0px 5px 5px -3px rgba(102,119,136,.03),0px 8px 10px 1px rgba(102,119,136,.3)',
    '0px 5px 6px -3px rgba(102,119,136,.03),0px 9px 12px 1px rgba(102,119,136,.3)',
    '0px 6px 6px -3px rgba(102,119,136,.03),0px 10px 14px 1px rgba(102,119,136,.3)',
    '0px 6px 7px -4px rgba(102,119,136,.03),0px 11px 15px 1px rgba(102,119,136,.3)',
    '0px 7px 8px -4px rgba(102,119,136,.03),0px 12px 17px 2px rgba(102,119,136,.3)',
    '0px 7px 8px -4px rgba(102,119,136,.03),0px 13px 19px 2px rgba(102,119,136,.3)',
    '0px 7px 9px -4px rgba(102,119,136,.03),0px 14px 21px 2px rgba(102,119,136,.3)',
    '0px 8px 9px -5px rgba(102,119,136,.03),0px 15px 22px 2px rgba(102,119,136,.3)',
    '0px 8px 10px -5px rgba(102,119,136,.03),0px 16px 24px 2px rgba(102,119,136,.3)',
    '0px 8px 11px -5px rgba(102,119,136,.03),0px 17px 26px 2px rgba(102,119,136,.3)',
    '0px 9px 11px -5px rgba(102,119,136,.03),0px 18px 28px 2px rgba(102,119,136,.3)',
    '0px 9px 12px -6px rgba(102,119,136,.03),0px 19px 29px 2px rgba(102,119,136,.3)',
    '0px 10px 13px -6px rgba(102,119,136,.03),0px 20px 31px 3px rgba(102,119,136,.3)',
    '0px 10px 13px -6px rgba(102,119,136,.03),0px 21px 33px 3px rgba(102,119,136,.3)',
    '0px 10px 14px -6px rgba(102,119,136,.03),0px 22px 35px 3px rgba(102,119,136,.3)',
    '0px 11px 14px -7px rgba(102,119,136,.03),0px 23px 36px 3px rgba(102,119,136,.3)',
    '0px 11px 15px -7px rgba(102,119,136,.03),0px 24px 38px 3px rgba(102,119,136,.3)',
  ],
});

function App() {
  const [isShowConsent, setIsShowConsent] = useState(false);
  const [isShowDialogConsent, setIsShowDialogConsent] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["localConsent"]);

  const onAcceptAllCookie = useCallback(() => {
    setCookie("performance", true);
    setCookie("function", true);
    setCookie("target", true);
    setCookie("consent", true);
    setIsShowConsent(false)
    setIsShowDialogConsent(false)
  }, []);

  useEffect(() => {
    if (typeof cookies.consent !== 'string') {
      setIsShowConsent(true);
    }
    if (typeof cookies.target === 'string' && cookies.target === 'true') {
      TagManager.initialize(tagManagerArgs);
    }
  }, []);

  const onAcceptSeperateCookie = useCallback((perfCookie, funcCookie, targCookie) => {
    setCookie("performance", !!perfCookie);
    setCookie("function", !!funcCookie);
    setCookie("target", !!targCookie);
    if (!!targCookie) {
      TagManager.initialize(tagManagerArgs);
    }
    setCookie("consent", true);
    setIsShowConsent(false)
    setIsShowDialogConsent(false)
  }, []);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={theme}>
        <Router>
          <Container
            maxWidth="xl"
            className="main-container pa-0"
            style={{ position: 'relative' }}
          >
            <Switch>
              <Route path="/blogs">
                <Blogs />
              </Route>
              <Route path="/plan/:planId">
                <Plan />
              </Route>
              <Route path="/" exact>
                <Home />
              </Route>
            </Switch>

            {isShowConsent && (
              <CookiesConsent
                setIsShowDialogConsent={setIsShowDialogConsent}
                onAccept={onAcceptAllCookie}
              />
            )}
            <CookiesConsentDialog
              onAccept={onAcceptSeperateCookie}
              onAcceptAll={onAcceptAllCookie}
              isShowDialogConsent={isShowDialogConsent}
              setIsShowDialogConsent={setIsShowDialogConsent}
            />
          </Container>
        </Router>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
