/**
 *
 * Blogs
 *
 */

import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { KeyboardDatePicker } from '@material-ui/pickers';
import 'date-fns';
import some from 'lodash.some';
import uniq from 'lodash.uniq';
import { get, sortBy } from 'lodash';
import axios from 'axios';
import React, { memo, useEffect, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import BlogCard from '../components/BlogCard';
import Header from '../components/Header';
import BlogDetail from './BlogDetail';
// import PropTypes from 'prop-types';

function Blogs() {
  const categoriesForUniq = [];
  const [blogs, setBlogs] = useState([]);
  blogs.filter(x => x.is_active).forEach((b) => (b.tags || []).forEach(t => categoriesForUniq.push(t)));
  const categories = uniq(categoriesForUniq, true);
  const { path } = useRouteMatch();
  const [filterBy, setFilterBy] = useState([]);
  const [filterBlogs, setFilterBlogs] = useState([]);
  const [filterAll, setFilterAll] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (filterBy.length > 0) {
      const filteredBlogs = blogs.filter(x => x.is_active).filter((b) => some(filterBy, (tags) => (b.tags || []).includes(tags))) 
      setFilterBlogs(filteredBlogs);
    } else {
      setFilterBlogs(blogs);
    }
  }, [filterBy]);

  useEffect(async () => {
    const blogsResponse = await axios.get(process.env.REACT_APP_BACKEND_API_URL + '/data/blogs')
    const blogsData = get(blogsResponse, 'data.response', [])
    const sortedBlogs = sortBy(blogsData, blog => parseInt(blog.created_at) * -1)
      setFilterBy([]);
      setBlogs(sortedBlogs);
      setFilterBlogs(sortedBlogs);
      setSelectedDate(null);
    return () => {
      setFilterBy([]);
      setFilterBlogs([]);
      setSelectedDate(null);
    };
  }, []);

  return (
    <Switch>
      <Route path={`${path}/:blogId`}>
        <BlogDetail />
      </Route>
      <Route path={path} exact>
        <Header
          breadcrumb={{
            links: [{ path: '/', name: 'หน้าแรก' }],
            current: 'เกาะกระแส',
          }}
        />
        <div className="blogs-page pa-6 bg-gray">
          <div className="flex flex-wrap justify-end align-center mb-6">
            {/* <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd MMM yyyy"
              id="date-picker-inline"
              className="ma-2"
              placeholder="Filter by date"
              value={selectedDate}
              onChange={(date) => {
                setSelectedDate(date);
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            /> */}

            <div className="flex flex-wrap justify-end">
              <Chip
                label="All"
                key="all"
                onClick={() => {
                  if (filterBy.length > 0) {
                    const isAll = !filterAll;
                    setFilterAll(!filterAll);
                    if (isAll) {
                      setFilterBy([]);
                    }
                  }
                }}
                className="ma-1"
                color="primary"
                variant={filterAll ? 'default' : 'outlined'}
              />
              {categories.filter(x => x !== "" && x !== " ").map((tag) => (
                <Chip
                  label={tag}
                  key={tag}
                  onClick={() => {
                    const idx = filterBy.indexOf(tag);
                    if (idx > -1) {
                      const filtered = [
                        ...filterBy.slice(0, idx),
                        ...filterBy.slice(idx + 1),
                      ];
                      if (filtered.length === 0) {
                        setFilterAll(true);
                      }
                      setFilterBy(filtered);
                    } else {
                      setFilterBy([...filterBy, tag]);
                      setFilterAll(false);
                    }
                  }}
                  className="ma-1"
                  color="primary"
                  variant={
                    !filterBy.includes(tag) || filterBy.length === 0
                      ? 'outlined'
                      : 'default'
                  }
                />
              ))}
            </div>
          </div>
          <Grid container spacing={3} className="mb-2">
            {filterBlogs.map((blog) => (
              <Grid item xs={12} sm={3} key={blog.id}>
                <BlogCard blog={blog} animate />
              </Grid>
            ))}
          </Grid>
        </div>
      </Route>
    </Switch>
  );
}

Blogs.propTypes = {};

export default memo(Blogs);
