/**
 *
 * Breadcrumb
 *
 */

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import HomeRounded from '@material-ui/icons/HomeRounded';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const BreadcrumbStyled = styled.div`
  margin-left: 2rem;

  li,
  a,
  p {
    font-size: 18px;
  }
`;

function Breadcrumb({ links, current, className }) {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <BreadcrumbStyled className={className}>
      {smUp ? (
        <Breadcrumbs aria-label="breadcrumb">
          {links.map((link, i) => {
            if (i === 2) {
              return (
                <Link
                  key={link.name}
                  className="flex align-center"
                  onClick={() => {
                    window.history.back();
                  }}
                >
                  {link.name === 'หน้าแรก' && <HomeRounded className="mr-2" />}
                  {link.name}
                </Link>
              );
            } else {
              return (
                <Link
                  to={link.path}
                  key={link.name}
                  className="flex align-center"
                >
                  {link.name === 'หน้าแรก' && <HomeRounded className="mr-2" />}
                  {link.name}
                </Link>
              );
            }
          })}
          <Typography color="primary" className="flex align-center">
            {current === 'หน้าแรก' && <HomeRounded className="mr-2" />}
            {current}
          </Typography>
        </Breadcrumbs>
      ) : (
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/" className="flex align-center">
            <HomeRounded className="mr-2" />
            หน้าแรก
          </Link>
        </Breadcrumbs>
      )}
    </BreadcrumbStyled>
  );
}

Breadcrumb.propTypes = {
  links: PropTypes.array,
  current: PropTypes.string,
  className: PropTypes.string,
};

export default memo(Breadcrumb);
