/**
 *
 * BlogDetail
 *
 */

import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import React, { createRef, memo, useEffect, useState } from 'react';
import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useScreenshot } from 'use-react-screenshot';
import axios from 'axios';
import { get } from 'lodash';
import Header from '../components/Header';
import HtmlContent from '../components/HtmlContent';
import LoadingContent from '../components/LoadingContent';
import ProductCard from '../components/ProductCard';
import blogs from '../mock-data/blogs';
import products from '../mock-data/products';
import ProductDetail from './ProductDetail';
// import HeroImage from '../components/HeroImage';

function BlogDetail() {
  const history = useHistory();
  const { blogId } = useParams();
  const { path } = useRouteMatch();
  const [isLoading, setIsLoading] = useState(true);
  const [blog, setBlog] = useState({});

  const screenshotRef = createRef(null);
  const [, takeScreenShot] = useScreenshot({
    type: 'image/jpeg',
    quality: 1.0,
  });

  const capture = () => takeScreenShot(screenshotRef.current);

  useEffect(async () => {
    const blogResponse = await axios.post(process.env.REACT_APP_BACKEND_API_URL + '/data/queryblog/' + blogId)
    const blogData = get(blogResponse, 'data.response', {})
    if (!blogData.id) {
      history.push('/')
    } else {
      setBlog(blogData);
      setIsLoading(false);
    }
  }, [blogId]);

  const getHtmlContent = () => {
    let content = '';

    if ((get(blog, 'content_image_urls', []) || []).length > 0) {
      (get(blog, 'content_image_urls', []) || []).forEach((img) => {
        content += `<img src='${img}' /><br/>`;
      });
    }

    return content;
  };
  return (
    <Switch>
      <Route path={`${path}/:productId`}>
        <ProductDetail
          breadcrumb={{
            links: [
              { path: '/', name: 'หน้าแรก' },
              { path: `/blogs/`, name: 'เกาะกระแส' },
              { path: `/blogs/${blogId}`, name: blog.title },
            ],
          }}
        />
      </Route>
      <Route path={path}>
        <Header
          breadcrumb={{
            links: [
              { path: '/', name: 'หน้าแรก' },
              { path: '/blogs', name: 'เกาะกระแส' },
            ],
            current: blog.title,
          }}
          isShare
          capture={capture}
          shareDetail={{ id: blog.id, type: 'blog-detail' }}
        />

        <div ref={screenshotRef} className="blog-detail-page pa-6">
          {isLoading ? (
            <LoadingContent />
          ) : (
            <>
              {/* <h1 className="mb-6">{blog.title}</h1> */}

              <HtmlContent content={getHtmlContent()} />

              {/* {blog.tags && blog.tags.length > 0 && (
                <div className="flex mb-8">
                  {(blog.tags || []).map((t) => (
                    <Chip
                      label={t}
                      className="mr-2"
                      color="primary"
                      variant="outlined"
                      key={t}
                    />
                  ))}
                </div>
              )} */}

              <div className="divider my-9" />

              <h2 className="mb-6">ผลิตภัณฑ์ที่เกี่ยวข้อง</h2>
              <Grid container spacing={3} className="mb-2">
                {(get(blog, 'related_products_data', []) || []).length > 0 &&
                  (get(blog, 'related_products_data', []) || []).map((r) => {
                    return (
                      <Grid item xs={12} sm={3} key={r}>
                        <ProductCard
                          product={r}
                          url={`/blogs/${blog.permalink}/${r.permalink}`}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </>
          )}
        </div>
      </Route>
    </Switch>
  );
}

BlogDetail.propTypes = {};

export default memo(BlogDetail);
