/**
 *
 * Plan
 *
 */

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import sortBy from "lodash.sortby";
import { get } from "lodash";
import axios from "axios";
import React, { createRef, memo, useEffect, useState } from "react";
import {
  useHistory,
  Link,
  Route,
  Switch,
  useParams,
  useRouteMatch
} from "react-router-dom";
import { useScreenshot } from "use-react-screenshot";
import Header from "../components/Header";
import HtmlContent from "../components/HtmlContent";
import LoadingContent from "../components/LoadingContent";
import Products from "./Products";
// import PropTypes from 'prop-types';

const isEncoded = str => {
  try {
    return decodeURIComponent(str) !== str
  } catch {
    return false
  }
}

function Plan() {
  const history = useHistory()
  const { path } = useRouteMatch();
  const { planId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [plan, setPlan] = useState({});
  const screenshotRef = createRef(null);
  const [, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0
  });

  const capture = () => takeScreenShot(screenshotRef.current);

  useEffect(async () => {
    const planResponse = await axios.post(
      process.env.REACT_APP_BACKEND_API_URL + "/data/queryplan/" +
        planId
    );
    const planData = get(planResponse, "data.response", {});
    setPlan(planData);
    if (!planData.id) {
      history.push('/')
    } else {
    setIsLoading(false);
    }
  }, [planId]);

  const getHtmlContent = () => {
    let content = "";

    (get(plan, 'content_image_urls', []) || []).forEach(c => {
      content += `<img src="${c}"/>`;
    });

    return content;
  };

  return (
    <Switch>
      <Route path={`${path}/:choiceId`}>
        <Products />
      </Route>
      <Route path={path}>
        <Header
          breadcrumb={{
            links: [{ path: "/", name: "หน้าแรก" }],
            current: plan.title
          }}
          isShare
          capture={capture}
          shareDetail={{ id: plan.id, type: "plan" }}
        />
        <div ref={screenshotRef} className="plan-page pa-6">
          {isLoading ? (
            <LoadingContent />
          ) : (
            <>
              <h1 className="mb-6">{plan.title}</h1>

              <Grid container spacing={3} className="mb-4" alignItems="center">
                <Grid item xs={12} sm={6}>
                  <div className="plan-radar box-4-3 fit-height flex-shrink radii">
                    <img src={plan.chart_url} alt="" />
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <div className="plan-icons flex flex-column justify-center flex-grow">
                    {sortBy(plan.choices, ["score"])
                      .reverse()
                      .map(c => (
                        <Link
                          key={c.id}
                          className={`flex align-center ${
                            (c.top_products || []).length > 0 ? "" : "disable"
                          }`}
                          to={
                            (c.top_products || []).length > 0
                              ? `/plan/${planId}/${c.permalink ? (isEncoded(c.permalink) ? c.permalink : encodeURIComponent(c.permalink)) : c.id}`
                              : "#"
                          }
                        >
                          <div className="plan-icon box-1-1 fit-height radii mr-4">
                            <img src={c.image_url} alt="" />
                          </div>

                          <Typography variant="h6">{c.title}</Typography>
                        </Link>
                      ))}
                  </div>
                </Grid>
              </Grid>

              <HtmlContent content={getHtmlContent()} />
            </>
          )}
        </div>
      </Route>
    </Switch>
  );
}

Plan.propTypes = {};

export default memo(Plan);
