/**
 *
 * BlogCard
 *
 */

import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";

function BlogCard({ blog, className, animate = false, timeout }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);

  const getContent = () => {
    return (
      <Paper className={`hover-card blog-card ${className}`} elevation={1}>
        <Link to={`/blogs/${blog.permalink}`}>
          <div className="box-16-9 fit-height radii-t">
            <img src={`${blog.image_url}`} alt="" />
          </div>

          <div className="px-4 py-3">
            <p>{blog.title}</p>
            <span className="caption mt-1">
              {new Date(parseInt(blog.created_at)).toLocaleDateString("th-TH", {
                day: "numeric",
                month: "long",
                year: "numeric"
              })}
            </span>
          </div>
        </Link>
      </Paper>
    );
  };

  if (animate) {
    return (
      <Grow in={show} timeout={timeout}>
        {getContent()}
      </Grow>
    );
  }

  return getContent();
}

BlogCard.propTypes = {
  blog: PropTypes.object,
  className: PropTypes.string,
  animate: PropTypes.bool,
  timeout: PropTypes.number
};

export default memo(BlogCard);
