/**
 *
 * ProductCard
 *
 */

import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';

function ProductCard({ product, rank, className, url }) {
  return (
    <Paper className={`hover-card product-card ${className}`} elevation={1}>
      <Link to={url}>
        {rank && <span className="rank">{rank}</span>}

        <div className="box-16-9 fit-height radii-t">
          <img src={product.image_url} alt="" />
        </div>

        <div className="px-4 py-3 flex align-center">
          {/* <span className="caption mb-1">{product.date}</span> */}
          <p className="mr-2">{product.title}</p>
          {product.isAward && (
            <img src={'/images/award.png'} alt="" width={24} />
          )}
        </div>
      </Link>
    </Paper>
  );
}

ProductCard.propTypes = {
  product: PropTypes.object,
  rank: PropTypes.number,
  className: PropTypes.string,
  url: PropTypes.string,
};

export default memo(ProductCard);
