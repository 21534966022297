/**
 *
 * HtmlContent
 *
 */

import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

function HtmlContent({ className = '', content }) {
  return <div className={`html-content ${className}`}>{parse(content)}</div>;
}

HtmlContent.propTypes = {
  className: PropTypes.string,
  content: PropTypes.any,
};

export default memo(HtmlContent);
